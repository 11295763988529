/* Banner.css */

:root {
  --second-color: rgba(188, 145, 92, 1);
  --second-color-light: rgba(188, 145, 92, 0.5);
  --hover-color: rgba(42, 56, 91, 0.5);
  --main-color: rgba(42, 56, 91, 1);
}
#web-banner-container.banner-container {
  width: 100%;
  margin-top: 80px;
  overflow: hidden;
}

#web-banner-container .banner-content {
  display: flex;
  flex-wrap: wrap;
}

#web-banner-container.banner-container .left-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px !important;
  position: relative;
}

#web-banner-container.banner-container .text-content {
  margin-bottom: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
#web-banner-container.banner-container .welcome-text {
  color: white;
  font-size: 3rem;
  width: 65%;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px;
  padding-right: 100px;
  position: relative;
}
#web-banner-container.banner-container .welcome-text::before {
  content: "";
  position: absolute;
  height: 70%;
  top: 50%;
  transform: translateY(-50%);
  width: 0px;
  right: 50px;
  border: 5px solid var(--main-color);
  margin-left: 20px;
}
#web-banner-container.banner-container .description {
  color: rgba(255, 255, 255, 0.822);
  font-size: 1.2rem;
  margin-bottom: 40px;
  padding-right: 50px;
  width: 70%;
}

#web-banner-container.banner-container .projects-button {
  background: var(--second-color) !important;
  border: none !important;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  margin-right: 50px;
}

#web-banner-container.banner-container .image-content {
  position: relative;
  height: 93vh;
  background-image: url("../../Image/Rectangle.png");
  background-size: cover;
  background-position: center;
}

#web-banner-container.banner-container .image-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 31, 31, 0.85);
}

#web-banner-container.banner-container .right-content {
  background-color: var(--second-color);
  margin: 0px;
  padding: 50px !important;
  padding-right: 40px !important;
}
#web-banner-container.banner-container .right-content .card-title {
  color: rgba(42, 42, 42, 1);
}
.banner-container .section-title {
  color: #fff;
  margin-bottom: 30px;
  font-size: 2rem;
}

.banner-container .cards-container {
  width: 100%;
}

.banner-container .custom-card {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  margin-bottom: 20px;
  background: #fff;
}

.banner-container .custom-card img {
  width: 60px;
  margin-right: 10px;
}

.banner-container .custom-card .card-description {
  color:var(--main-color);
  padding: 5px;
  border-radius: 5px;
}
.banner-container .custom-card a {
  text-decoration: none;
  color: var(--second-color);
}
@media (max-width: 768px) {
  .banner-container .welcome-text {
    width: 95%;
  }
  #web-banner-container.banner-container .welcome-text {
    font-size: 2rem;
    width: 90%;
  }
}
