.ArchivedRequests {
    position: relative;
    width: 100%;
    height: 55vh; 
    display: flex;
    align-items: center;
    margin-top: 83px;
  }
  
  .ArchivedRequests .text-content {
    z-index: 2; 
    color: white;
    padding: 40px 140px;
    margin-top: 70px;
    width: 90%;
  }
  .ArchivedRequests .welcome-text {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    margin-right: 30px;
    position: relative;
  }
  
  .ArchivedRequests .welcome-text::before {
    content: "";
    position: absolute;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    right: -30px;
    border: 5px solid rgba(76, 175, 79, 1);
  }

  .ArchivedRequests .description {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .ArchivedRequests .welcome-text {
      font-size: 2rem;
    }
    .ArchivedRequests .text-content{
      width: 100% !important;
      padding: 40px 40px !important;

    }
    .ArchivedRequests .description {
      font-size: 1rem;
    }
  }
  