#BarChart-Cards.cards{
    margin: 20px 20px 0px 20px;
    border-radius: 10px;
}
#BarChart-Cards .cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
#BarChart-Cards .card {
    margin: 5px;
    padding: 20px;
    border-radius: 10px;
    background-color:  white;
    color: rgba(21, 29, 72, 1);
    transition: transform 0.3s ease;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row; */
    gap: 10px;
    flex: 24%;
}

#BarChart-Cards .card:hover {
    transform: scale(1.05); 
}

#BarChart-Cards .card-img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

#BarChart-Cards .card-number {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-right: 10px;
}

#BarChart-Cards .card-title {
    font-size: 18px;
    font-weight: 500;
    color:rgba(66, 81, 102, 1);
    
}

#BarChart-Cards .card-icon{
    padding: 10px 13px;
    color: white;
    border-radius: 50% ;
    font-size: 19px;
    background-color: #fa5a7d;   
}


@media (max-width: 768px) {
    .body_container {
        overflow: auto; 
        max-width: 100%;
    }
    .search-requests-container .request-card{
        display: block !important;
    }
    .account-card{
        flex-direction: column !important;
    }
    #BarChart-Cards .card{
        flex-direction: column;
    }
}
@media (max-width: 1200px) {
    #BarChart-Cards .card {
        flex: 1 1 45%; 
    }
}

@media (max-width: 768px) {
    #BarChart-Cards .card {
        flex: 1 1 100%; 
    }
}
