
.Contactus{
    color: rgba(235, 236, 238, 1);
    margin: 0px;
}
.phoneNumber{
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 25px;
}
.rounded-circle{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.list-unstyled li{
    margin: 10px 0px;
}

.list-unstyled li a{
    color:  rgba(212, 215, 221, 1)!important;

}