.header {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
    background-color: white;
    color: white;
}

.header-image {
    flex: 0 0 auto; 
    margin-left: 20px; 
    margin-top: 10px;
}

.header-image img {
    max-width: 100%; 
    height: auto; 
    width: 100px; 
}
