.notFound {
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notFound img{
    width: 500px;

}