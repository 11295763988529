:root {
  --second-color: rgba(188, 145, 92, 1);
  --second-color-light: rgba(188, 145, 92, 0.5);
  --hover-color: rgba(42, 56, 91, 0.5);
  --main-color: rgba(42, 56, 91, 1);
}

.accounts-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}
.apDiv.Accounts #four {
  background-color: white;
  color: var(--main-color);
  backdrop-filter: "blur(5.5px)";
  border: "1px solid rgba( 255, 255, 255, 0.18 )";
}
.apDiv.Engineer #five {
  background-color: white;
  color: var(--main-color);
  backdrop-filter: "blur(5.5px)";
  border: "1px solid rgba( 255, 255, 255, 0.18 )";
}
.accounts-content {
  flex: 3;
}
.filter-buttons {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.filter-buttons div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-buttons button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: white;
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}

.filter-buttons .active {
  background-color: white;
  color: var(--main-color);
  font-family: "Cairo", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.accounts-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.add-account {
  width: fit-content;
}
.add-account a {
  background: rgba(76, 175, 79, 1) !important;
  color: white;
  font-family: "Cairo", sans-serif;
  border-radius: 5px;
  padding: 5px 25px;
}
.account-card {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  transition: transform 0.2s;
}
.account-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.account-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.account-name,
.account-email {
  margin: 0;
}

.account-type {
  font-weight: bold;
}

.account-type.active {
  color: green;
}

.account-type.inactive {
  color: gray;
}

.account-actions {
  display: flex;
  gap: 10px;
}

.account-actions button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}
.account-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  position: relative;
}
.account-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 15px;
}

.account-name,
.account-email,
.account-type {
  margin: 0;
  padding: 0;
}

.options-icon {
  position: relative;
  cursor: pointer;
}
.options-icon svg {
  font-size: 30px;
  color: rgb(139, 139, 139);
}
.popup-menu {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px;
  z-index: 99;
}

.popup-menu button {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
  border: none;
  background: none;
  cursor: pointer;
}

.edit-button {
  background: rgba(91, 146, 255, 0.178) !important;
  padding: 0px 16px;
  border-radius: 5px;
  color: rgba(91, 147, 255, 1);
  font-size: 13px;
}
.edit-button svg {
  color: rgba(91, 147, 255, 1);
  font-size: 14px;
  margin-left: 5px;
}
.delete-button {
  background: rgba(231, 29, 54, 0.178) !important;
  padding: 0px;
  border-radius: 5px;
  color: rgba(231, 29, 54, 1);
  font-size: 13px;
  margin: 5px !important;
  text-align: center;
}
.delete-button svg {
  color: rgba(231, 29, 54, 1);
  font-size: 14px;
  margin-left: 5px;
}
.popup-menu button:hover {
  background-color: #f0f0f0;
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.confirm-button {
  background: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button {
  background: gray;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .filter-buttons {
    flex-direction: column;
  }
  .filter-buttons div {
    flex-direction: column;
  }
}
