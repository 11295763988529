.apDiv{
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Cairo', sans-serif;
    background: var(--main3, #F8F8F8);
}
.apDiv.dashBoard #one{
    background-color: white;
    color: var(--main-color);
    backdrop-filter: "blur(5.5px)";
    border: "1px solid rgba( 255, 255, 255, 0.18 )";
}
.titleDashBoard,.subTitle{
    color: #101b14;
}
.numberUsers{
    display: grid;
    grid-template-columns: repeat( auto-fill , minmax(300px , 1fr));
    gap: 20px;
}
.numberUsers .firstContent{
    margin-top: 0px;
    margin-bottom: 20px;
}
.numberUsers  .firstContent .numberTeacher{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 345px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
}
.numberUsers .firstContent .numberTeacher p{
    color: rgba(177, 177, 177, 0.69);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
}
.numberUsers .firstContent .numberTeacher h5{
    color: #017457;
    font-size: 32px;
    font-style: normal;
    margin: 0px;
    margin-top: -15px;
}
.numberUsers ion-icon {
    font-size: 40px;
    color: var(--main_color);
}
.SparklineChart{
    display: grid;
    grid-template-columns: repeat( auto-fill , minmax(350px , 1fr));
    gap: 20px;
    margin-bottom: 20px;
}
.Bills{
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}
.addTabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 20px;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.table-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.new-invoice-btn {
    background-color: rgba(93, 95, 239, 1) ; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    font-family: 'Cairo', sans-serif;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.new-invoice-btn:hover {
    background-color: rgb(51, 53, 177);
}

@media (max-width: 980px) {
    .numberUsers  .firstContent .numberTeacher{
        width: 100%;
    }
    .SparklineChart{
        display: grid;
        grid-template-columns: repeat( auto-fill , minmax(300px , 1fr));
        gap: 20px;
        margin-bottom: 20px;
    }
}